.nav-menu {
  height:100vh;
  width:100vw;
  z-index: 999;
  background: #1c1c1c;
  position: fixed;
  transition: ease-in-out 0.3s;
  padding-top: 5rem;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 1rem;
    padding: 0 2rem;
    li {
      height: 3rem;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1.2rem;
      cursor: pointer;
      background: #1c1c1c;
      border-radius: 5px;
      transition: 0.3s;

      &:hover {
        background: #2e2e2e;
      }
    }
  }
}

.close {
  left: 100%;
}

.open {
  left: 0%;
}