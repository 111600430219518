nav {
  width: 100%;
  height: 3rem;
  // background: red;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  z-index: 1000;
}

.flex-nav {
  display: none;
}

.icon {
  cursor: pointer;
}

.logo {
  height: 80%;
}

.menu {
  position: fixed;
  height: 1.5rem;
  right: 1rem;
}

@media screen and (min-width: 1080px) {
  // nav {
  //   padding: 0 5rem;
  //   justify-content: space-between;
  // }

  .menu {
    display: none;
  }

  .flex-nav {
    display: block;
    right: 5rem;
    position: fixed;
    font-weight: 700;
    ul {
      display: flex;
      gap: 3rem;
      list-style: none;
    }
  }
}