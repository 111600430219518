.main {
  position: relative;
  // height: 100vh;
  background-color: #1c1c1c;
  // top: 50vh;
  padding: 0 1rem 5rem 1rem;
}

.showcase {
  padding: 7rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showcase-vid {
  display: flex;
  justify-content: center;

  iframe {
    width: 100%;
    max-width: 560px;
  }
}

.showcase-pic {
  padding-top: 3rem;
}

.pic-cont {
  padding-top: 1rem;
  line-height: 0;

  img {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 768px) {
  .showcase-pic {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .pic-cont {
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  .showcase-pic {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}