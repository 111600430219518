.contact {
  padding-top: 10rem;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
  }

  p {
    color: #cbcbcb;
    text-align: center;
  }
}