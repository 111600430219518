.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 0 2rem;
}

.footer-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;

  a {
    text-decoration: none;
    color: white;
    font-size: 1.3rem;
  }
}

.footer-social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding-bottom: 2rem;
}

.footer-logo {
  height: 3rem;
}