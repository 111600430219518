.hero {
  display: flex;
  justify-content: center;
  overflow: hidden;

  video {
    height: 100vh;
  }
}

@media screen and (min-width: 1080px) {
  .hero {
    width: 100vw;
    height: 100vh;
    position: relative;

    video {
      width: 100vw;
      height: max-content;
      transform: translateY(-20%);
      overflow: hidden;
    }
  }
}